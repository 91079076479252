import React from 'react'
import styled from 'styled-components'
import { addTransition, Breakpoints } from 'theme'
import { MilestonesData } from 'data'

const StyledWrapper = styled.div`
    height: 130%;
    display: grid;
    grid-template: 1fr / 1fr;
    grid-gap: 50px;
    padding-top: 450px;
    padding-bottom: 200px;

    @media (min-width: ${Breakpoints.sm}px) {
        padding-bottom: 50px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
        padding-top: 250px;
    }
`

const StyledShowcase = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.foreground};
    color: ${({ theme }) => theme.background};
    border-radius: 100%;
    width: 400px;
    height: 400px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    ${addTransition('background-color, border-color')}

    @media (min-width: ${Breakpoints.md2}px) {
        width: 467px;
        height: 467px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
        width: 600px;
        height: 600px;
    }
`

interface EntryProps {
    isActive: boolean
}

const StyledEntry = styled.div<EntryProps>`
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: 0.5s ease;
    display: grid;
    position: absolute;
    width: 416px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-row-gap: 2vw;
    grid-column-gap: 10%;
    grid-template-areas:
        'company company'
        'headline headline'
        'detail1 detail2';

    @media (max-width: ${Breakpoints.lg}px) {
        display: ${({ isActive }) => (isActive ? `flex` : `none`)};
        flex-direction: column;
        grid-template-areas:
            'company company'
            'headline headline'
            'headline headline';
        text-align: center;
        align-items: center;
    }

    & > * {
        line-height: 1.4em;
        letter-spacing: 0.7px;
    }
`

const StyledCompany = styled.div`
    font-size: 26px;
    grid-area: company;
    text-align: center;
    width: 90%;

    @media (min-width: ${Breakpoints.md2}px) {
        width: 100%;
        font-size: 32px;
    }
    @media (min-width: ${Breakpoints.lg}px) {
        font-size: 42px;
    }

`

const StyledHeadline = styled.div`
    font-size: 20px;
    grid-area: headline;
    font-weight: 400;
    text-align: center;
    width: 80%;

    @media (min-width: ${Breakpoints.md2}px) {
        font-size: 22px;
        width: 100%;
    }
    @media (min-width: ${Breakpoints.lg}px) {
        font-size: 28px;
    }
`

interface ShowcaseProps {
    date: string
}

const Showcase: React.FC<ShowcaseProps> = ({ date }) => {
    return (
        <StyledWrapper>
            <StyledShowcase>
                {MilestonesData.map((e) => (
                    <StyledEntry key={e.date} isActive={date === e.date ? true : false}>
                        <StyledCompany>{e.title}</StyledCompany>
                        <StyledHeadline>{e.headline}</StyledHeadline>
                    </StyledEntry>
                ))}
            </StyledShowcase>
            <StyledShowcase>
                {MilestonesData.map((e) => (
                    <StyledEntry key={e.date} isActive={date === e.date ? true : false}>
                        <StyledHeadline>{e.detail1}</StyledHeadline>
                    </StyledEntry>
                ))}
            </StyledShowcase>
            <StyledShowcase>
                {MilestonesData.map((e) => (
                    <StyledEntry key={e.date} isActive={date === e.date ? true : false}>
                        <StyledHeadline>{e.detail2}</StyledHeadline>
                    </StyledEntry>
                ))}
            </StyledShowcase>
        </StyledWrapper>
    )
}

export default Showcase
