import React from 'react'
import styled from 'styled-components'
import { Github } from 'styled-icons/boxicons-logos'
import { SocialLinkedin } from '@styled-icons/foundation/SocialLinkedin'
import { SocialBehance } from '@styled-icons/foundation/SocialBehance'
import { Breakpoints } from 'theme'
import { IconWithStyles } from 'icons/iconWithStyle'

const StyledLinks = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: ${Breakpoints.md2 - 1}px) {
        bottom: 20px;
        width: 125px;
    }
    @media (min-width: ${Breakpoints.md2}px) {
        top: 90%;
        width: 175px;
    }
`

const Links: React.FC = () => {
    return (
        <StyledLinks>
            <a href="https://www.linkedin.com/in/designdevy/" rel="noopener noreferrer" target="_blank">
                {IconWithStyles(SocialLinkedin)}
            </a>
            <a href="https://github.com/designdevy" rel="noopener noreferrer" target="_blank">
                {IconWithStyles(Github)}
            </a>
            <a href="https://www.behance.net/designdevy" rel="noopener noreferrer" target="_blank">
                {IconWithStyles(SocialBehance)}
            </a>
        </StyledLinks>
    )
}

export default Links
