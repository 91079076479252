import {
    StyledBulma,
    StyledRNative,
    StyledPHP,
    StyledContentful,
    StyledGhost,
    StyledSanity,
    StyledHashnode,
    StyledWordpress,
    StyledDrupal,
    StyledJoomla,
    StyledDocu,
    StyledStorybook,
    StyledKoa,
    StyledPyramid,
    StyledCherryPy,
    StyledSymfony,
    StyledLaravel,
    StyledNeo4J,
    StyledPostGres,
    StyledSequelize,
    StyledYarn,
    StyledNPM,
    StyledLerna,
    StyledLoopback,
    StyledGCP,
    StyledAWS,
    StyledAzure,
    StyledDocker,
    StyledKubernetes,
    StyledSelenium,
    StyledApollo,
    StyledSQL,
    StyledNoSQL,
    StyledStyledSys,
    StyledWebpack,
    StyledRollup,
    StyledBrowserify,
    StyledGulp,
    StyledGrunt,
    StyledBabel,
    StyledMongoDB,
    StyledMongoose,
    StyledFirebase,
    StyledGraphQL,
    StyledFlutter,
    StyledGSAP,
    StyledRSpring,
    StyledD3,
    StyledThreeJS,
    StyledTSLint,
    StyledESLint,
    StyledPrettier,
    StyledJest,
    StyledJasmine,
    StyledMocha,
    StyledChai,
    StyledKarma,
    StyledExpress,
    StyledHapi,
    StyledElectron,
    StyledSocketIO,
    StyledElixir,
    StyledRust,
    StyledGo,
    StyledDeno,
    StyledJSS,
    StyledPostCSS,
    StyledTailwind,
    StyledPython,
    StyledJava,
    StyledCPlus2,
    StyledBootstrap,
    StyledSketch,
    StyledFigma,
    StyledXD,
    StyledFramer,
    StyledSass,
    StyledLess,
    StyledStylus,
    StyledStyledC,
    StyledGatsby,
    StyledNext,
    StyledNuxt,
    StyledVSCode,
    StyledVStudio,
    StyledVim,
    StyledCSandbox,
    StyledReplit,
    StyledNano,
    StyledSvelte,
    StyledCSharp,
    StyledDotNet,
    StyledJQuery,
    StyledMotion,
    StyledPreact,
    StyledEmber,
    StyledBackbone,
    StyledES6,
    StyledCSS3,
    StyledHTML5,
    StyledReact,
    StyledTS,
    StyledNodeJS,
    StyledRedux,
    StyledVue,
    StyledRRouter,
    StyledFlask,
    StyledDjango,
    StyledGit,
    StyledAngular,
    StyledSpringIO,
    StyledHibernate,
    StyledKafka,
    StyledHadoop,
    StyledSpark,
    StyledCodepen,
    StyledRuby,
    StyledCLang,
    StyledCypress,
    StyledEmmet,
    StyledRails,
    StyledVercel,
    StyledVuetify,
    StyledVueX,
    StyledVueRouter,
    StyledAxios,
} from './icons'

export {
    StyledBulma,
    StyledRNative,
    StyledPHP,
    StyledContentful,
    StyledGhost,
    StyledSanity,
    StyledHashnode,
    StyledWordpress,
    StyledDrupal,
    StyledJoomla,
    StyledDocu,
    StyledStorybook,
    StyledKoa,
    StyledPyramid,
    StyledCherryPy,
    StyledSymfony,
    StyledLaravel,
    StyledNeo4J,
    StyledPostGres,
    StyledSequelize,
    StyledYarn,
    StyledNPM,
    StyledLerna,
    StyledLoopback,
    StyledGCP,
    StyledAWS,
    StyledAzure,
    StyledDocker,
    StyledKubernetes,
    StyledSelenium,
    StyledApollo,
    StyledSQL,
    StyledNoSQL,
    StyledStyledSys,
    StyledWebpack,
    StyledRollup,
    StyledBrowserify,
    StyledGulp,
    StyledGrunt,
    StyledBabel,
    StyledMongoDB,
    StyledMongoose,
    StyledFirebase,
    StyledGraphQL,
    StyledFlutter,
    StyledGSAP,
    StyledRSpring,
    StyledD3,
    StyledThreeJS,
    StyledTSLint,
    StyledESLint,
    StyledPrettier,
    StyledJest,
    StyledJasmine,
    StyledMocha,
    StyledChai,
    StyledKarma,
    StyledExpress,
    StyledHapi,
    StyledElectron,
    StyledSocketIO,
    StyledElixir,
    StyledRust,
    StyledGo,
    StyledDeno,
    StyledJSS,
    StyledPostCSS,
    StyledTailwind,
    StyledPython,
    StyledJava,
    StyledCPlus2,
    StyledBootstrap,
    StyledSketch,
    StyledFigma,
    StyledXD,
    StyledFramer,
    StyledSass,
    StyledLess,
    StyledStylus,
    StyledStyledC,
    StyledGatsby,
    StyledNext,
    StyledNuxt,
    StyledVSCode,
    StyledVStudio,
    StyledVim,
    StyledCSandbox,
    StyledReplit,
    StyledNano,
    StyledSvelte,
    StyledCSharp,
    StyledDotNet,
    StyledJQuery,
    StyledMotion,
    StyledPreact,
    StyledEmber,
    StyledBackbone,
    StyledES6,
    StyledCSS3,
    StyledHTML5,
    StyledReact,
    StyledTS,
    StyledNodeJS,
    StyledRedux,
    StyledVue,
    StyledRRouter,
    StyledFlask,
    StyledDjango,
    StyledGit,
    StyledAngular,
    StyledSpringIO,
    StyledHibernate,
    StyledKafka,
    StyledHadoop,
    StyledSpark,
    StyledCodepen,
    StyledRuby,
    StyledCLang,
    StyledCypress,
    StyledEmmet,
    StyledRails,
    StyledVercel,
    StyledVuetify,
    StyledVueX,
    StyledVueRouter,
    StyledAxios,
}

const CraftData: any = {
    'Languages & Platforms': [
        StyledHTML5,
        StyledES6,
        StyledTS,
        StyledNodeJS,
        StyledDeno,
        StyledElectron,
        StyledCSharp,
        StyledDotNet,
        StyledPHP,
        StyledPython,
        StyledJava,
        StyledRuby,
        StyledCLang,
        StyledCPlus2,
        StyledElixir,
        StyledRust,
        StyledGo,
        StyledFlutter,
    ],
    'Frameworks & Libraries': [
        StyledReact,
        StyledRNative,
        StyledRedux,
        StyledRRouter,
        StyledExpress,
        StyledHapi,
        StyledSocketIO,
        StyledBulma,
        StyledTailwind,
        StyledBootstrap,
        StyledPreact,
        StyledAngular,
        StyledVue,
        StyledVueRouter,
        StyledVueX,
        StyledAxios,
        StyledSvelte,
        StyledEmber,
        StyledBackbone,
        StyledKoa,
        StyledLoopback,
        StyledPyramid,
        StyledSymfony,
        StyledLaravel,
        StyledDjango,
        StyledFlask,
        StyledCherryPy,
        StyledSpringIO,
        StyledHibernate,
        StyledRails,
    ],
    Jamstack: [StyledGatsby, StyledNext, StyledNuxt, StyledVercel],
    'Blogging & CMS': [
        StyledGhost,
        StyledSanity,
        StyledContentful,
        StyledHashnode,
        StyledWordpress,
        StyledDrupal,
        StyledJoomla,
    ],
    Docs: [StyledDocu, StyledStorybook],
    'Animation & 3D': [StyledJQuery, StyledGSAP, StyledRSpring, StyledMotion, StyledD3, StyledThreeJS],
    Styling: [
        StyledCSS3,
        StyledSass,
        StyledLess,
        StyledStylus,
        StyledStyledC,
        StyledJSS,
        StyledPostCSS,
        StyledStyledSys,
        StyledVuetify,
    ],
    Linting: [StyledTSLint, StyledESLint, StyledPrettier],
    Testing: [StyledJest, StyledJasmine, StyledMocha, StyledChai, StyledKarma, StyledSelenium, StyledCypress],
    Tooling: [
        StyledWebpack,
        StyledRollup,
        StyledBrowserify,
        StyledBabel,
        StyledGulp,
        StyledGrunt,
        StyledYarn,
        StyledNPM,
        StyledLerna,
        StyledGit,
        StyledEmmet,
    ],
    Data: [
        StyledSQL,
        StyledNoSQL,
        StyledMongoDB,
        StyledMongoose,
        StyledFirebase,
        StyledApollo,
        StyledGraphQL,
        StyledPostGres,
        StyledSequelize,
        StyledNeo4J,
        StyledKafka,
        StyledHadoop,
        StyledSpark,
    ],
    Cloud: [StyledGCP, StyledAWS, StyledAzure, StyledDocker, StyledKubernetes],
    IDEs: [StyledVSCode, StyledVStudio, StyledVim, StyledNano, StyledCodepen, StyledCSandbox, StyledReplit],
    'UI Design': [StyledSketch, StyledFigma, StyledFramer, StyledXD],
}

export const UnlockedTech: any = {
    HTML5: true,
    CSS3: true,
    VSCode: true,
    VStudio: true,
    Vim: true,
    Nano: true,
    CSandbox: true,
    Codepen: true,
    Replit: true,
    Git: true,
    React: true,
    Figma: true,
    Framer: true,
    XD: true,
    Sketch: true,
    ES6: true,
    TS: true,
    NodeJS: true,
    Redux: true,
    RRouter: true,
    Express: true,
    Bootstrap: true,
    Tailwind: true,
    Gatsby: true,
    Next: true,
    Ghost: true,
    Sanity: true,
    Hashnode: true,
    Docu: true,
    Storybook: true,
    JQuery: true,
    Motion: true,
    RSpring: true,
    Sass: true,
    StyledC: true,
    JSS: true,
    PostCSS: true,
    StyledSys: true,
    ESLint: true,
    TSLint: true,
    Prettier: true,
    Jest: true,
    SQL: true,
    NoSQL: true,
    MongoDB: true,
    Firebase: true,
    GraphQL: true,
    Webpack: true,
    Babel: true,
    Gulp: true,
    Grunt: true,
    Yarn: true,
    NPM: true,
    Lerna: true,
    Wordpress: true,
    Emmet: true,
    Vercel: true,
    Nuxt: true,
    Vue: true,
    VueRouter: true,
    VueX: true,
    Axios: true,
    Vuetify: true,
}

export const LNDTech: any = {
    CSharp: true,
    DotNet: true,
    Python: true,
    Bulma: true,
    Hapi: true,
    Contentful: true,
    GSAP: true,
    D3: true,
    ThreeJS: true,
    Less: true,
    Stylus: true,
    Selenium: true,
    Docker: true,
    Apollo: true,
    Mongoose: true,
    Rollup: true,
    Browserify: true,
    GCP: true,
    PHP: true,
    Cypress: true,
}

export default CraftData
