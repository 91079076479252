import iconWithStyles from './iconWithStyle'

/* AWS Logo */

import { ReactComponent as AWS } from './svgFiles/AWS.svg'
export const StyledAWS = iconWithStyles(AWS)
StyledAWS.displayName = 'AWS'

/* Angular Logo */

import { ReactComponent as Angular } from './svgFiles/Angular.svg'
export const StyledAngular = iconWithStyles(Angular)
StyledAngular.displayName = 'Angular'

/* Apollo Logo */

import { ReactComponent as Apollo } from './svgFiles/Apollo.svg'
export const StyledApollo = iconWithStyles(Apollo)
StyledApollo.displayName = 'Apollo'

/* Axios Logo */

import { ReactComponent as Axios } from './svgFiles/Axios.svg'
export const StyledAxios = iconWithStyles(Axios)
StyledAxios.displayName = 'Axios'

/* Azure Logo */

import { ReactComponent as Azure } from './svgFiles/Azure.svg'
export const StyledAzure = iconWithStyles(Azure)
StyledAzure.displayName = 'Azure'

/* Babel Logo */

import { ReactComponent as Babel } from './svgFiles/Babel.svg'
export const StyledBabel = iconWithStyles(Babel)
StyledBabel.displayName = 'Babel'

/* Backbone Logo */

import { ReactComponent as Backbone } from './svgFiles/Backbone.svg'
export const StyledBackbone = iconWithStyles(Backbone)
StyledBackbone.displayName = 'Backbone'

/* Bootstrap Logo */

import { ReactComponent as Bootstrap } from './svgFiles/Bootstrap.svg'
export const StyledBootstrap = iconWithStyles(Bootstrap)
StyledBootstrap.displayName = 'Bootstrap'

/* Browserify Logo */

import { ReactComponent as Browserify } from './svgFiles/Browserify.svg'
export const StyledBrowserify = iconWithStyles(Browserify)
StyledBrowserify.displayName = 'Browserify'

/* Bulma Logo */

import { ReactComponent as Bulma } from './svgFiles/Bulma.svg'
export const StyledBulma = iconWithStyles(Bulma)
StyledBulma.displayName = 'Bulma'

/* CLang Logo */

import { ReactComponent as CLang } from './svgFiles/CLang.svg'
export const StyledCLang = iconWithStyles(CLang)
StyledCLang.displayName = 'CLang'

/* CPlus2 Logo */

import { ReactComponent as CPlus2 } from './svgFiles/CPlus2.svg'
export const StyledCPlus2 = iconWithStyles(CPlus2)
StyledCPlus2.displayName = 'CPlus2'

/* CSS3 Logo */

import { ReactComponent as CSS3 } from './svgFiles/CSS3.svg'
export const StyledCSS3 = iconWithStyles(CSS3)
StyledCSS3.displayName = 'CSS3'

/* CSandbox Logo */

import { ReactComponent as CSandbox } from './svgFiles/CSandbox.svg'
export const StyledCSandbox = iconWithStyles(CSandbox)
StyledCSandbox.displayName = 'CSandbox'

/* CSharp Logo */

import { ReactComponent as CSharp } from './svgFiles/CSharp.svg'
export const StyledCSharp = iconWithStyles(CSharp)
StyledCSharp.displayName = 'CSharp'

/* Chai Logo */

import { ReactComponent as Chai } from './svgFiles/Chai.svg'
export const StyledChai = iconWithStyles(Chai)
StyledChai.displayName = 'Chai'

/* CherryPy Logo */

import { ReactComponent as CherryPy } from './svgFiles/CherryPy.svg'
export const StyledCherryPy = iconWithStyles(CherryPy)
StyledCherryPy.displayName = 'CherryPy'

/* Codepen Logo */

import { ReactComponent as Codepen } from './svgFiles/Codepen.svg'
export const StyledCodepen = iconWithStyles(Codepen)
StyledCodepen.displayName = 'Codepen'

/* Contentful Logo */

import { ReactComponent as Contentful } from './svgFiles/Contentful.svg'
export const StyledContentful = iconWithStyles(Contentful)
StyledContentful.displayName = 'Contentful'

/* Cypress Logo */

import { ReactComponent as Cypress } from './svgFiles/Cypress.svg'
export const StyledCypress = iconWithStyles(Cypress)
StyledCypress.displayName = 'Cypress'

/* D3 Logo */

import { ReactComponent as D3 } from './svgFiles/D3.svg'
export const StyledD3 = iconWithStyles(D3)
StyledD3.displayName = 'D3'

/* Deno Logo */

import { ReactComponent as Deno } from './svgFiles/Deno.svg'
export const StyledDeno = iconWithStyles(Deno)
StyledDeno.displayName = 'Deno'

/* Django Logo */

import { ReactComponent as Django } from './svgFiles/Django.svg'
export const StyledDjango = iconWithStyles(Django)
StyledDjango.displayName = 'Django'

/* Docker Logo */

import { ReactComponent as Docker } from './svgFiles/Docker.svg'
export const StyledDocker = iconWithStyles(Docker)
StyledDocker.displayName = 'Docker'

/* Docu Logo */

import { ReactComponent as Docu } from './svgFiles/Docu.svg'
export const StyledDocu = iconWithStyles(Docu)
StyledDocu.displayName = 'Docu'

/* DotNet Logo */

import { ReactComponent as DotNet } from './svgFiles/DotNet.svg'
export const StyledDotNet = iconWithStyles(DotNet)
StyledDotNet.displayName = 'DotNet'

/* Drupal Logo */

import { ReactComponent as Drupal } from './svgFiles/Drupal.svg'
export const StyledDrupal = iconWithStyles(Drupal)
StyledDrupal.displayName = 'Drupal'

/* ES6 Logo */

import { ReactComponent as ES6 } from './svgFiles/ES6.svg'
export const StyledES6 = iconWithStyles(ES6)
StyledES6.displayName = 'ES6'

/* ESLint Logo */

import { ReactComponent as ESLint } from './svgFiles/ESLint.svg'
export const StyledESLint = iconWithStyles(ESLint)
StyledESLint.displayName = 'ESLint'

/* Electron Logo */

import { ReactComponent as Electron } from './svgFiles/Electron.svg'
export const StyledElectron = iconWithStyles(Electron)
StyledElectron.displayName = 'Electron'

/* Elixir Logo */

import { ReactComponent as Elixir } from './svgFiles/Elixir.svg'
export const StyledElixir = iconWithStyles(Elixir)
StyledElixir.displayName = 'Elixir'

/* Ember Logo */

import { ReactComponent as Ember } from './svgFiles/Ember.svg'
export const StyledEmber = iconWithStyles(Ember)
StyledEmber.displayName = 'Ember'

/* Emmet Logo */

import { ReactComponent as Emmet } from './svgFiles/Emmet.svg'
export const StyledEmmet = iconWithStyles(Emmet)
StyledEmmet.displayName = 'Emmet'

/* Express Logo */

import { ReactComponent as Express } from './svgFiles/Express.svg'
export const StyledExpress = iconWithStyles(Express)
StyledExpress.displayName = 'Express'

/* Figma Logo */

import { ReactComponent as Figma } from './svgFiles/Figma.svg'
export const StyledFigma = iconWithStyles(Figma)
StyledFigma.displayName = 'Figma'

/* Firebase Logo */

import { ReactComponent as Firebase } from './svgFiles/Firebase.svg'
export const StyledFirebase = iconWithStyles(Firebase)
StyledFirebase.displayName = 'Firebase'

/* Flask Logo */

import { ReactComponent as Flask } from './svgFiles/Flask.svg'
export const StyledFlask = iconWithStyles(Flask)
StyledFlask.displayName = 'Flask'

/* Flutter Logo */

import { ReactComponent as Flutter } from './svgFiles/Flutter.svg'
export const StyledFlutter = iconWithStyles(Flutter)
StyledFlutter.displayName = 'Flutter'

/* Framer Logo */

import { ReactComponent as Framer } from './svgFiles/Framer.svg'
export const StyledFramer = iconWithStyles(Framer)
StyledFramer.displayName = 'Framer'

/* GCP Logo */

import { ReactComponent as GCP } from './svgFiles/GCP.svg'
export const StyledGCP = iconWithStyles(GCP)
StyledGCP.displayName = 'GCP'

/* GSAP Logo */

import { ReactComponent as GSAP } from './svgFiles/GSAP.svg'
export const StyledGSAP = iconWithStyles(GSAP)
StyledGSAP.displayName = 'GSAP'

/* Gatsby Logo */

import { ReactComponent as Gatsby } from './svgFiles/Gatsby.svg'
export const StyledGatsby = iconWithStyles(Gatsby)
StyledGatsby.displayName = 'Gatsby'

/* Ghost Logo */

import { ReactComponent as Ghost } from './svgFiles/Ghost.svg'
export const StyledGhost = iconWithStyles(Ghost)
StyledGhost.displayName = 'Ghost'

/* Git Logo */

import { ReactComponent as Git } from './svgFiles/Git.svg'
export const StyledGit = iconWithStyles(Git)
StyledGit.displayName = 'Git'

/* Go Logo */

import { ReactComponent as Go } from './svgFiles/Go.svg'
export const StyledGo = iconWithStyles(Go)
StyledGo.displayName = 'Go'

/* GraphQL Logo */

import { ReactComponent as GraphQL } from './svgFiles/GraphQL.svg'
export const StyledGraphQL = iconWithStyles(GraphQL)
StyledGraphQL.displayName = 'GraphQL'

/* Grunt Logo */

import { ReactComponent as Grunt } from './svgFiles/Grunt.svg'
export const StyledGrunt = iconWithStyles(Grunt)
StyledGrunt.displayName = 'Grunt'

/* Gulp Logo */

import { ReactComponent as Gulp } from './svgFiles/Gulp.svg'
export const StyledGulp = iconWithStyles(Gulp)
StyledGulp.displayName = 'Gulp'

/* HTML5 Logo */

import { ReactComponent as HTML5 } from './svgFiles/HTML5.svg'
export const StyledHTML5 = iconWithStyles(HTML5)
StyledHTML5.displayName = 'HTML5'

/* Hadoop Logo */

import { ReactComponent as Hadoop } from './svgFiles/Hadoop.svg'
export const StyledHadoop = iconWithStyles(Hadoop)
StyledHadoop.displayName = 'Hadoop'

/* Hapi Logo */

import { ReactComponent as Hapi } from './svgFiles/Hapi.svg'
export const StyledHapi = iconWithStyles(Hapi)
StyledHapi.displayName = 'Hapi'

/* Hashnode Logo */

import { ReactComponent as Hashnode } from './svgFiles/Hashnode.svg'
export const StyledHashnode = iconWithStyles(Hashnode)
StyledHashnode.displayName = 'Hashnode'

/* Hibernate Logo */

import { ReactComponent as Hibernate } from './svgFiles/Hibernate.svg'
export const StyledHibernate = iconWithStyles(Hibernate)
StyledHibernate.displayName = 'Hibernate'

/* JQuery Logo */

import { ReactComponent as JQuery } from './svgFiles/JQuery.svg'
export const StyledJQuery = iconWithStyles(JQuery)
StyledJQuery.displayName = 'JQuery'

/* JSS Logo */

import { ReactComponent as JSS } from './svgFiles/JSS.svg'
export const StyledJSS = iconWithStyles(JSS)
StyledJSS.displayName = 'JSS'

/* Jasmine Logo */

import { ReactComponent as Jasmine } from './svgFiles/Jasmine.svg'
export const StyledJasmine = iconWithStyles(Jasmine)
StyledJasmine.displayName = 'Jasmine'

/* Java Logo */

import { ReactComponent as Java } from './svgFiles/Java.svg'
export const StyledJava = iconWithStyles(Java)
StyledJava.displayName = 'Java'

/* Jest Logo */

import { ReactComponent as Jest } from './svgFiles/Jest.svg'
export const StyledJest = iconWithStyles(Jest)
StyledJest.displayName = 'Jest'

/* Joomla Logo */

import { ReactComponent as Joomla } from './svgFiles/Joomla.svg'
export const StyledJoomla = iconWithStyles(Joomla)
StyledJoomla.displayName = 'Joomla'

/* Kafka Logo */

import { ReactComponent as Kafka } from './svgFiles/Kafka.svg'
export const StyledKafka = iconWithStyles(Kafka)
StyledKafka.displayName = 'Kafka'

/* Karma Logo */

import { ReactComponent as Karma } from './svgFiles/Karma.svg'
export const StyledKarma = iconWithStyles(Karma)
StyledKarma.displayName = 'Karma'

/* Koa Logo */

import { ReactComponent as Koa } from './svgFiles/Koa.svg'
export const StyledKoa = iconWithStyles(Koa)
StyledKoa.displayName = 'Koa'

/* Kubernetes Logo */

import { ReactComponent as Kubernetes } from './svgFiles/Kubernetes.svg'
export const StyledKubernetes = iconWithStyles(Kubernetes)
StyledKubernetes.displayName = 'Kubernetes'

/* Laravel Logo */

import { ReactComponent as Laravel } from './svgFiles/Laravel.svg'
export const StyledLaravel = iconWithStyles(Laravel)
StyledLaravel.displayName = 'Laravel'

/* Lerna Logo */

import { ReactComponent as Lerna } from './svgFiles/Lerna.svg'
export const StyledLerna = iconWithStyles(Lerna)
StyledLerna.displayName = 'Lerna'

/* Less Logo */

import { ReactComponent as Less } from './svgFiles/Less.svg'
export const StyledLess = iconWithStyles(Less)
StyledLess.displayName = 'Less'

/* Loopback Logo */

import { ReactComponent as Loopback } from './svgFiles/Loopback.svg'
export const StyledLoopback = iconWithStyles(Loopback)
StyledLoopback.displayName = 'Loopback'

/* Mocha Logo */

import { ReactComponent as Mocha } from './svgFiles/Mocha.svg'
export const StyledMocha = iconWithStyles(Mocha)
StyledMocha.displayName = 'Mocha'

/* MongoDB Logo */

import { ReactComponent as MongoDB } from './svgFiles/MongoDB.svg'
export const StyledMongoDB = iconWithStyles(MongoDB)
StyledMongoDB.displayName = 'MongoDB'

/* Mongoose Logo */

import { ReactComponent as Mongoose } from './svgFiles/Mongoose.svg'
export const StyledMongoose = iconWithStyles(Mongoose)
StyledMongoose.displayName = 'Mongoose'

/* Motion Logo */

import { ReactComponent as Motion } from './svgFiles/Motion.svg'
export const StyledMotion = iconWithStyles(Motion)
StyledMotion.displayName = 'Motion'

/* NPM Logo */

import { ReactComponent as NPM } from './svgFiles/NPM.svg'
export const StyledNPM = iconWithStyles(NPM)
StyledNPM.displayName = 'NPM'

/* Nano Logo */

import { ReactComponent as Nano } from './svgFiles/Nano.svg'
export const StyledNano = iconWithStyles(Nano)
StyledNano.displayName = 'Nano'

/* Neo4J Logo */

import { ReactComponent as Neo4J } from './svgFiles/Neo4J.svg'
export const StyledNeo4J = iconWithStyles(Neo4J)
StyledNeo4J.displayName = 'Neo4J'

/* Next Logo */

import { ReactComponent as Next } from './svgFiles/Next.svg'
export const StyledNext = iconWithStyles(Next)
StyledNext.displayName = 'Next'

/* NoSQL Logo */

import { ReactComponent as NoSQL } from './svgFiles/NoSQL.svg'
export const StyledNoSQL = iconWithStyles(NoSQL)
StyledNoSQL.displayName = 'NoSQL'

/* NodeJS Logo */

import { ReactComponent as NodeJS } from './svgFiles/NodeJS.svg'
export const StyledNodeJS = iconWithStyles(NodeJS)
StyledNodeJS.displayName = 'NodeJS'

/* Nuxt Logo */

import { ReactComponent as Nuxt } from './svgFiles/Nuxt.svg'
export const StyledNuxt = iconWithStyles(Nuxt)
StyledNuxt.displayName = 'Nuxt'

/* PHP Logo */

import { ReactComponent as PHP } from './svgFiles/PHP.svg'
export const StyledPHP = iconWithStyles(PHP)
StyledPHP.displayName = 'PHP'

/* PostCSS Logo */

import { ReactComponent as PostCSS } from './svgFiles/PostCSS.svg'
export const StyledPostCSS = iconWithStyles(PostCSS)
StyledPostCSS.displayName = 'PostCSS'

/* PostGres Logo */

import { ReactComponent as PostGres } from './svgFiles/PostGres.svg'
export const StyledPostGres = iconWithStyles(PostGres)
StyledPostGres.displayName = 'PostGres'

/* Preact Logo */

import { ReactComponent as Preact } from './svgFiles/Preact.svg'
export const StyledPreact = iconWithStyles(Preact)
StyledPreact.displayName = 'Preact'

/* Prettier Logo */

import { ReactComponent as Prettier } from './svgFiles/Prettier.svg'
export const StyledPrettier = iconWithStyles(Prettier)
StyledPrettier.displayName = 'Prettier'

/* Pyramid Logo */

import { ReactComponent as Pyramid } from './svgFiles/Pyramid.svg'
export const StyledPyramid = iconWithStyles(Pyramid)
StyledPyramid.displayName = 'Pyramid'

/* Python Logo */

import { ReactComponent as Python } from './svgFiles/Python.svg'
export const StyledPython = iconWithStyles(Python)
StyledPython.displayName = 'Python'

/* RNative Logo */

import { ReactComponent as RNative } from './svgFiles/RNative.svg'
export const StyledRNative = iconWithStyles(RNative)
StyledRNative.displayName = 'RNative'

/* RRouter Logo */

import { ReactComponent as RRouter } from './svgFiles/RRouter.svg'
export const StyledRRouter = iconWithStyles(RRouter)
StyledRRouter.displayName = 'RRouter'

/* RSpring Logo */

import { ReactComponent as RSpring } from './svgFiles/RSpring.svg'
export const StyledRSpring = iconWithStyles(RSpring)
StyledRSpring.displayName = 'RSpring'

/* Rails Logo */

import { ReactComponent as Rails } from './svgFiles/Rails.svg'
export const StyledRails = iconWithStyles(Rails)
StyledRails.displayName = 'Rails'

/* React Logo */

import { ReactComponent as React } from './svgFiles/React.svg'
export const StyledReact = iconWithStyles(React)
StyledReact.displayName = 'React'

/* Redux Logo */

import { ReactComponent as Redux } from './svgFiles/Redux.svg'
export const StyledRedux = iconWithStyles(Redux)
StyledRedux.displayName = 'Redux'

/* Replit Logo */

import { ReactComponent as Replit } from './svgFiles/Replit.svg'
export const StyledReplit = iconWithStyles(Replit)
StyledReplit.displayName = 'Replit'

/* Rollup Logo */

import { ReactComponent as Rollup } from './svgFiles/Rollup.svg'
export const StyledRollup = iconWithStyles(Rollup)
StyledRollup.displayName = 'Rollup'

/* Ruby Logo */

import { ReactComponent as Ruby } from './svgFiles/Ruby.svg'
export const StyledRuby = iconWithStyles(Ruby)
StyledRuby.displayName = 'Ruby'

/* Rust Logo */

import { ReactComponent as Rust } from './svgFiles/Rust.svg'
export const StyledRust = iconWithStyles(Rust)
StyledRust.displayName = 'Rust'

/* SQL Logo */

import { ReactComponent as SQL } from './svgFiles/SQL.svg'
export const StyledSQL = iconWithStyles(SQL)
StyledSQL.displayName = 'SQL'

/* Sanity Logo */

import { ReactComponent as Sanity } from './svgFiles/Sanity.svg'
export const StyledSanity = iconWithStyles(Sanity)
StyledSanity.displayName = 'Sanity'

/* Sass Logo */

import { ReactComponent as Sass } from './svgFiles/Sass.svg'
export const StyledSass = iconWithStyles(Sass)
StyledSass.displayName = 'Sass'

/* Selenium Logo */

import { ReactComponent as Selenium } from './svgFiles/Selenium.svg'
export const StyledSelenium = iconWithStyles(Selenium)
StyledSelenium.displayName = 'Selenium'

/* Sequelize Logo */

import { ReactComponent as Sequelize } from './svgFiles/Sequelize.svg'
export const StyledSequelize = iconWithStyles(Sequelize)
StyledSequelize.displayName = 'Sequelize'

/* Sketch Logo */

import { ReactComponent as Sketch } from './svgFiles/Sketch.svg'
export const StyledSketch = iconWithStyles(Sketch)
StyledSketch.displayName = 'Sketch'

/* SocketIO Logo */

import { ReactComponent as SocketIO } from './svgFiles/SocketIO.svg'
export const StyledSocketIO = iconWithStyles(SocketIO)
StyledSocketIO.displayName = 'SocketIO'

/* Spark Logo */

import { ReactComponent as Spark } from './svgFiles/Spark.svg'
export const StyledSpark = iconWithStyles(Spark)
StyledSpark.displayName = 'Spark'

/* SpringIO Logo */

import { ReactComponent as SpringIO } from './svgFiles/SpringIO.svg'
export const StyledSpringIO = iconWithStyles(SpringIO)
StyledSpringIO.displayName = 'SpringIO'

/* Storybook Logo */

import { ReactComponent as Storybook } from './svgFiles/Storybook.svg'
export const StyledStorybook = iconWithStyles(Storybook)
StyledStorybook.displayName = 'Storybook'

/* StyledC Logo */

import { ReactComponent as StyledC } from './svgFiles/StyledC.svg'
export const StyledStyledC = iconWithStyles(StyledC)
StyledStyledC.displayName = 'StyledC'

/* StyledSys Logo */

import { ReactComponent as StyledSys } from './svgFiles/StyledSys.svg'
export const StyledStyledSys = iconWithStyles(StyledSys)
StyledStyledSys.displayName = 'StyledSys'

/* Stylus Logo */

import { ReactComponent as Stylus } from './svgFiles/Stylus.svg'
export const StyledStylus = iconWithStyles(Stylus)
StyledStylus.displayName = 'Stylus'

/* Svelte Logo */

import { ReactComponent as Svelte } from './svgFiles/Svelte.svg'
export const StyledSvelte = iconWithStyles(Svelte)
StyledSvelte.displayName = 'Svelte'

/* Symfony Logo */

import { ReactComponent as Symfony } from './svgFiles/Symfony.svg'
export const StyledSymfony = iconWithStyles(Symfony)
StyledSymfony.displayName = 'Symfony'

/* TS Logo */

import { ReactComponent as TS } from './svgFiles/TS.svg'
export const StyledTS = iconWithStyles(TS)
StyledTS.displayName = 'TS'

/* TSLint Logo */

import { ReactComponent as TSLint } from './svgFiles/TSLint.svg'
export const StyledTSLint = iconWithStyles(TSLint)
StyledTSLint.displayName = 'TSLint'

/* Tailwind Logo */

import { ReactComponent as Tailwind } from './svgFiles/Tailwind.svg'
export const StyledTailwind = iconWithStyles(Tailwind)
StyledTailwind.displayName = 'Tailwind'

/* ThreeJS Logo */

import { ReactComponent as ThreeJS } from './svgFiles/ThreeJS.svg'
export const StyledThreeJS = iconWithStyles(ThreeJS)
StyledThreeJS.displayName = 'ThreeJS'

/* VSCode Logo */

import { ReactComponent as VSCode } from './svgFiles/VSCode.svg'
export const StyledVSCode = iconWithStyles(VSCode)
StyledVSCode.displayName = 'VSCode'

/* VStudio Logo */

import { ReactComponent as VStudio } from './svgFiles/VStudio.svg'
export const StyledVStudio = iconWithStyles(VStudio)
StyledVStudio.displayName = 'VStudio'

/* Vercel Logo */

import { ReactComponent as Vercel } from './svgFiles/Vercel.svg'
export const StyledVercel = iconWithStyles(Vercel)
StyledVercel.displayName = 'Vercel'

/* Vim Logo */

import { ReactComponent as Vim } from './svgFiles/Vim.svg'
export const StyledVim = iconWithStyles(Vim)
StyledVim.displayName = 'Vim'

/* Vue Logo */

import { ReactComponent as Vue } from './svgFiles/Vue.svg'
export const StyledVue = iconWithStyles(Vue)
StyledVue.displayName = 'Vue'

/* VueRouter Logo */

import { ReactComponent as VueRouter } from './svgFiles/VueRouter.svg'
export const StyledVueRouter = iconWithStyles(VueRouter)
StyledVueRouter.displayName = 'VueRouter'

/* VueX Logo */

import { ReactComponent as VueX } from './svgFiles/VueX.svg'
export const StyledVueX = iconWithStyles(VueX)
StyledVueX.displayName = 'VueX'

/* Vuetify Logo */

import { ReactComponent as Vuetify } from './svgFiles/Vuetify.svg'
export const StyledVuetify = iconWithStyles(Vuetify)
StyledVuetify.displayName = 'Vuetify'

/* Webpack Logo */

import { ReactComponent as Webpack } from './svgFiles/Webpack.svg'
export const StyledWebpack = iconWithStyles(Webpack)
StyledWebpack.displayName = 'Webpack'

/* Wordpress Logo */

import { ReactComponent as Wordpress } from './svgFiles/Wordpress.svg'
export const StyledWordpress = iconWithStyles(Wordpress)
StyledWordpress.displayName = 'Wordpress'

/* XD Logo */

import { ReactComponent as XD } from './svgFiles/XD.svg'
export const StyledXD = iconWithStyles(XD)
StyledXD.displayName = 'XD'

/* Yarn Logo */

import { ReactComponent as Yarn } from './svgFiles/Yarn.svg'
export const StyledYarn = iconWithStyles(Yarn)
StyledYarn.displayName = 'Yarn'

