import React from 'react'
import styled from 'styled-components'
import { addTransition, Breakpoints } from 'theme'
import { MilestonesData } from 'data'

const StyledWrapper = styled.div`
    z-index: 10;
    display: flex;
    width: 390px;
    height: 7%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 250px;
    white-space: nowrap;
    flex-wrap: wrap;
    transform: translate(-50%, 0);
    user-select: none;

    @media (min-width: ${Breakpoints.sm}px) {
        top: 250px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
        width: auto;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
        left: 30px;
        transform: translate(0%, 0%);
        top: 450px;
    }
`

interface EntryProps {
    isSelected: boolean
}

const StyledEntry = styled.a<EntryProps>`
    margin-right: 20px;
    padding-bottom: 5px;
    cursor: pointer;
    color: ${({ theme }) => theme.foreground};
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    ${addTransition('color')}

    @media (min-width: ${Breakpoints.lg}px) {
        margin-right: 0;
        font-size: 18px;
        margin-bottom: 22px;
    }
    @media (min-width: ${Breakpoints.xxl}px) {
        font-size: 22px;
        margin-bottom: 25px;
    }
`

const StyledBar = styled.span<EntryProps>`
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 2px;
    opacity: 0.75;
    background-color: ${({ theme }) => theme.foreground};
    ${addTransition('width, background-color')}
    display: inline-block;
    width: ${({ isSelected }) => (isSelected ? '100%' : '15px')};
`

interface MilestonesProps {
    date: string
    setDate: React.Dispatch<React.SetStateAction<string>>
}

const Milestones: React.FC<MilestonesProps> = ({ date, setDate }) => {
    return (
        <StyledWrapper>
            {MilestonesData.map((e) => (
                <StyledEntry
                    isSelected={date === e.date ? true : false}
                    key={e.date}
                    onClick={(_event) => setDate(() => (date !== e.date ? e.date : date))}
                >
                    {e.date}
                    <StyledBar isSelected={date === e.date ? true : false} />
                </StyledEntry>
            ))}
        </StyledWrapper>
    )
}

export default Milestones
