import { css } from 'styled-components'

const LightTheme = {
    background: '#ffffff',
    foreground: '#000000',
}
const DarkTheme = {
    background: '#000000',
    foreground: '#ffffff',
}

const Breakpoints = {
    vsm: 450,
    // => @media (min-width: 450px) { ... }

    sm: 660,
    // => @media (min-width: 640px) { ... }

    md: 768,
    // => @media (min-width: 768px) { ... }

    md2: 850,
    // => @media (min-width: 768px) { ... }

    lg: 1024,
    // => @media (min-width: 1024px) { ... }

    xl: 1280,
    // => @media (min-width: 1280px) { ... }

    xxl: 1440,
    // => @media (min-width: 1440px) { ... }

    xxxl: 1600,
    // => @media (min-width: 1600px) { ... }
}

const addTransition = (property: string) => {
    return css`
        transition-property: ${property};
        transition-duration: 0.35s;
        /* transition-timing-function: cubic-bezier(0.4, 0.03, 0, 1); */
    `
}

export { LightTheme, DarkTheme, Breakpoints, addTransition }
