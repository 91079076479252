import { createSlice } from '@reduxjs/toolkit'
import { State } from 'redux/store'

export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        value: 'light',
    },
    reducers: {
        makeItBright: (state) => {
            state.value = 'light'
        },
        makeItDark: (state) => {
            state.value = 'dark'
        },
    },
})

// Action creators are generated for each case reducer function
export const { makeItBright, makeItDark } = themeSlice.actions

export const selectTheme = (state: State) => state.theme.value

export default themeSlice.reducer
