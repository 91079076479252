import styled from 'styled-components'
import { addTransition } from 'theme'

const StyledTool = styled.div<{ unlocked: boolean; lnd: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    margin-top: 30px;
    opacity: 0.2;
    ${({ unlocked }) => `${unlocked && 'opacity: 1'}`};
    ${({ lnd }) => `${lnd && 'opacity: 0.6'}`};
    width: 75px;
    height: 75px;

    & > * {
        color: ${({ theme }) => theme.foreground};
        ${addTransition('color')}
    }
`

export default StyledTool
