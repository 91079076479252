import React, { useState } from "react"
import StyledPage from './StyledPage'
import styled, { css } from "styled-components"
import { Breakpoints } from "theme"
import { StyledReact, StyledStyledSys, StyledVue, StyledNext, StyledStyledC, StyledVercel, StyledNodeJS, StyledVueRouter, StyledVueX, StyledAxios, StyledYarn, StyledTailwind, StyledSanity } from 'icons'
import { addTransition } from 'theme'
import { IconWithStyles } from "icons/iconWithStyle"
import { Github } from 'styled-icons/boxicons-logos'
import { ExternalLink } from 'styled-icons/evaicons-solid'

const projectsData = [
  {
    name: "This Website",
    description: "My Personal Portfolio",
    content: "Built from scratch, a diary where I jot down my life events; a todo list for me where I can gamify my career by unlocking new tech skills when I acquire them and a detailed Resume for recruiters and hiring managers.",
    stack: [StyledReact, StyledStyledSys, StyledStyledC, StyledVercel, StyledNodeJS, StyledYarn],
    repo: "private",
  }, {
    name: "DesignDev.io",
    description: "My Design and Dev Blog",
    content: "A place where I post about the learning journey of different tech stacks and some cheat sheets for future reference.",
    stack: [StyledReact, StyledTailwind, StyledSanity, StyledVercel, StyledNodeJS, StyledYarn],
    link: "https://www.designdev.io//",
    repo: "private",
  }, {
    name: "Agoda.Design",
    description: "Portfolio Website for Design at Agoda featuring the life at Agoda in general and the culture within the Design Org",
    content: "Initially built with vanilla React. Later migrateed to the hooks api and added typescript to make things organized and easy to develop new features.",
    stack: [StyledReact, StyledNext, StyledStyledC, StyledVercel, StyledNodeJS, StyledYarn],
    link: "https://agoda.design/",
    repo: "private",
  }, {
    name: "ThisWeekNews!",
    description: "A Single Page App that displays latest News",
    content: "A one week Coding Challenge to learn Vue from scratch and build a News App using the News Api. It allowed me to unloack a new skill",
    stack: [StyledVue, StyledVueRouter, StyledVueX, StyledAxios],
    link: "http://this-week-news.vercel.app/",
    repo: "https://github.com/designdevy/news-app",
  }, {
    name: "Coming Soon...",
    description: "More stuff on the way",
    placeHolder: "I am currently compiling all the projects that I have done slowly and will be adding them here as time allows.",
  }]

const StyledCover = styled.div`
    width: 95vw;
    height: 50vh;
    border: 5px solid ${({ theme }) => theme.foreground};
    color: ${({ theme }) => theme.foreground};
    ${addTransition('border-color')}
    border-radius: 7px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    padding: 20px;

    @media (min-width: ${Breakpoints.sm}px) {
        width: 70vw;
    }

    @media (min-width: ${Breakpoints.xl}px) {
        width: 60vw;;
    }

    @media (min-width: ${Breakpoints.xxl}px) {
        width: 50vw;
    }

    & a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
    }
`

const StyledCoverContainer = styled.div<{ isActive: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    z-index: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: 0.5s ease;
    box-sizing: border-box;
`

const StyledCoverItem = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        'title title'
        'description description'
        'detail stack';
`

const StyledTitle = styled.div`
    font-size: 22px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    grid-area: title;

    @media (min-width: ${Breakpoints.lg}px) {
        font-size: 26px;
    }
    @media (min-width: ${Breakpoints.xl}px) {
        font-size: 32px;
    }
`

const StyledTitleContent = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledDescription = styled.div`
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    line-height: 1.3;
    grid-area: description;
    
    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 22px;
    }
`

const StyledContent = styled.div`
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 5px solid ${({ theme }) => theme.foreground};
    ${addTransition('border-color')}
    line-height: 1.3;
    padding-top: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    grid-area: detail;

    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 18px;
    }
    @media (min-width: ${Breakpoints.lg}px) {
        font-size: 18px;
    }
    @media (min-width: ${Breakpoints.xl}px) {
        font-size: 22px;
    }
`

const StyledStack = styled.div`
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    line-height: 1.3;
    padding-top: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    grid-area: stack;

    @media (min-width: ${Breakpoints.lg}px) {
        font-size: 18px;
    }
    @media (min-width: ${Breakpoints.xl}px) {
        font-size: 22px;
    }
`

const StyledStackContent = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledLinks = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`

const StyledStackIcons = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const StyledTool = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    margin-top: 10px;
    margin-right: 10px;
    opacity: 1;
    width: 30px;
    height: 30px;

    & > * {
        color: ${({ theme }) => theme.foreground};
        ${addTransition('color')}
    }
`

const StyledPlaceholder = styled.div`
    font-size: 22px;
    font-weight: 400;
    line-height: 1.3;
    grid-area: detail / detail / stack / stack;
`

const Carousel = styled("div")`
  position: absolute;
  left: 50%;
  bottom: 112px;
  transform: translate3d(-50%,0,0) scale(0.4);
  transform-origin: center bottom;
  user-select: none;

  @media (min-width: ${Breakpoints.md2}px) {
    left: 50%;
    bottom: 52px;
  }
`

const CarouselItems = styled("div") <{ itemCount: number; activeIndex: number }>`
  display: flex;
  position: relative;
  transition: transform 0.5s ease;

  ${props =>
    `transform: translate3d(calc(${((props.itemCount - 1) / 2) * 220}px - ${props.activeIndex * 220}px), 0, 0);`}
`

const CarouselItem = styled("div") <{ active: boolean }>`
  position: relative;
  flex-shrink: 0;
  width: 300px;
  height: 100px;
  margin: 0 15px;
  cursor: pointer;
  animation: deactivateCarouselItem 0.3s ease;
  transition: transform 0.3s ease, border-width 0.1s ease;
  border: 5px solid ${({ theme }) => theme.foreground};
  border-radius: 7px;
  box-sizing: border-box;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.foreground};
  ${addTransition('background-color, color, border-color, transform')}

  @media (min-width: ${Breakpoints.md2}px) {
    font-size: 48px;
    width: 400px;
    height: 200px;
  }

  &:hover {
    transform: scale(1.15);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${props =>
    props.active &&
    css`
      transform: scale(1.5);
      z-index: 2;
      animation: activateCarouselItem 0.5s ease;
      border-width: 5px;
      background-color: ${({ theme }) => theme.foreground};
      color: ${({ theme }) => theme.background};

      &:hover {
        transform: scale(1.5);
        border-width: 5px;
      }
    `}

  @keyframes deactivateCarouselItem {
    0% {
      transform: scale(1.5);
      z-index: 3;
      background-color: ${({ theme }) => theme.foreground};
      color: ${({ theme }) => theme.background};
    }
    1% {
      z-index: 2;
    }
    99% {
      z-index: 2;
    }
    100% {
      transform: scale(1);
      z-index: 1;
      background-color: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.foreground};
    }
  }

  @keyframes activateCarouselItem {
    0% {
      transform: scale(1.15);
      z-index: 3;
      background-color: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.foreground};
    }
    100% {
      transform: scale(1.5);
      z-index: 3;
      background-color: ${({ theme }) => theme.foreground};
      color: ${({ theme }) => theme.background};
    }
  }
`

const Projects: React.FC = () => {
  const [activeProject, setActiveProject] = useState(0)

  function isActive(project: any) {
    return activeProject === project ? true : false
  }

  const projects = projectsData.map((project, i) => {
    return <CarouselItem key={i} active={isActive(i)} onClick={() => setActiveProject(i)}>{project.name}</CarouselItem>
  })

  const projectsContent = projectsData.map((project, i) => {
    return <StyledCoverContainer key={i} isActive={isActive(i)}>
      <StyledCoverItem  >
        <StyledTitle>
          <StyledTitleContent>
            {
              project.link ? (
                <a href={project.link} rel="noopener noreferrer" target="_blank" style={{ display: 'flex', }}>
                  {project.name}
                  <div style={{ marginLeft: '10px', }}>{IconWithStyles(ExternalLink, 20)}</div>
                </a>
              ) : project.name
            }
          </StyledTitleContent>
        </StyledTitle>
        <StyledDescription>
          {project.description}
        </StyledDescription>
        {
          project.placeHolder && (
            <StyledPlaceholder>
              {project.placeHolder}
            </StyledPlaceholder>
          )
        }
        {project.content && (
          <StyledContent>
            {project.content}
          </StyledContent>
        )}
        {
          (project.stack || project.repo) && (
            <StyledStack>
              <StyledStackContent>
                {
                  project.stack && project.stack.length > 0 && (
                    <>
                      Built with:
                      <StyledStackIcons>
                        {
                          project.stack.map((Icon: any) => {
                            return (
                              <StyledTool
                                key={Icon.displayName}
                              >
                                <Icon />
                              </StyledTool>
                            )
                          })
                        }
                      </StyledStackIcons>
                    </>
                  )
                }
                {
                  project.repo && (
                    <StyledLinks>
                      Source Code:
                      {
                        project.repo === 'private' ? (
                          <em><code>Private</code></em>
                        )
                          : (
                            <a href={project.repo} rel="noopener noreferrer" target="_blank" style={{ marginTop: '10px', }}>
                              {IconWithStyles(Github)}
                            </a>
                          )}
                    </StyledLinks>
                  )
                }
              </StyledStackContent>
            </StyledStack>
          )
        }
      </StyledCoverItem >
    </StyledCoverContainer >
  })

  return (
    <StyledPage>
      <StyledCover>
        {projectsContent}
      </StyledCover>
      <Carousel>
        <CarouselItems activeIndex={activeProject} itemCount={projectsData.length}>
          {projects}
        </CarouselItems>
      </Carousel>
    </StyledPage>
  )
}

export default Projects
