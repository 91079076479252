import React from 'react'
import styled from 'styled-components'
import { addTransition } from 'theme'

const iconWithStyle = (Component: any) => {
    return styled(Component)`
        fill: ${({ theme }) => `${theme.foreground}`};
        ${addTransition('fill')}
    `
}

const StyledIcon = styled.div<{ opacity: number }>`
    color: ${({ theme }) => `${theme.foreground}`};
    ${addTransition('color')}
    opacity: ${({ opacity }) => `${opacity / 100}`};
`

export function IconWithStyles(Icon: any, size: number = 32, opacity: number = 100) {
    return (
        <StyledIcon opacity={opacity}>
            <Icon size={size} />
        </StyledIcon>
    )
}

export default iconWithStyle
