import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { addTransition, Breakpoints } from 'theme'

export const StyledCursor = styled.div`
    width: 100px;
    height: 100px;
    background-color: #fff;
    backface-visibility: hidden;
    border-radius: 50%;
    position: absolute;
    z-index: 100;
    mix-blend-mode: difference;
    pointer-events: none;
    ${addTransition('opacity')}
    display: none;

    @media (max-width: ${Breakpoints.sm - 1}px) {
        display: none;
    }
    @media (min-width: ${Breakpoints.sm}px) {
        display: block;
    }
`

const Cursor = () => {
    const cursorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const node = cursorRef.current as any
        document.addEventListener('mousemove', (e) => {
            let x = e.pageX
            let y = e.pageY
            node.style.left = x - 50 + 'px'
            node.style.top = y - 50 + 'px'
        })

        return document.addEventListener('mousemove', (e) => {
            let x = e.pageX
            let y = e.pageY
            node.style.left = x - 50 + 'px'
            node.style.top = y - 50 + 'px'
        })
    }, [])

    useEffect(() => {
        const node = cursorRef.current as any
        document.addEventListener('mouseleave', (e) => {
            let x = e.pageX
            let y = e.pageY
            node.style.left = x - 50 + 'px'
            node.style.top = y - 50 + 'px'
        })

        return document.addEventListener('mouseleave', (e) => {
            let x = e.pageX
            let y = e.pageY
            node.style.left = x - 50 + 'px'
            node.style.top = y - 50 + 'px'
        })
    }, [])
    return <StyledCursor ref={cursorRef} />
}

export default Cursor
