import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

const StyledMotionPage = styled(motion.div)`
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => `${theme.background}`};
`

const animations = {
    hidden: {
        opacity: 0,
        transition: {
            ease: 'easeInOut',
            duration: 0.5
        }
    },
    visible: {
        opacity: 1,
        transition: {
            ease: 'easeInOut',
            duration: 0.5
        }
    },
    exit: {
        opacity: 0,
        transition: {
            ease: 'easeInOut',
            duration: 0.5
        }
    }
}

const StyledPage = ({ children }: any) => {
    return <StyledMotionPage variants={animations} initial="hidden" animate="visible" exit="exit">{children}</StyledMotionPage>
}

export default StyledPage
