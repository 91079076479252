import React from 'react'
import styled from 'styled-components'
import { addTransition, Breakpoints } from 'theme'

const StyledName = styled.div`
    position: relative;
    font-family: 'Oswald', sans-serif;
    user-select: none;
    color: ${({ theme }) => `${theme.foreground}`};
    ${addTransition('color')}
`

const StyledFirstName = styled.div`
    position: absolute;
    font-size: 11px;
    top: 15px;
    left: 64px;

    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 15px;
        top: 20px;
        left: 84px;
    }
    @media (min-width: ${Breakpoints.md2}px) {
        font-size: 23px;
        top: 30px;
        left: 126px;
    }
    @media (min-width: ${Breakpoints.xl}px) {
        font-size: 30px;
        top: 41px;
        left: 168px;
    }
`

const StyledLastNameContainer = styled.div`
    display: flex;
    position: relative;
    font-size: 120px;

    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 160px;
    }
    @media (min-width: ${Breakpoints.md2}px) {
        font-size: 240px;
    }
    @media (min-width: ${Breakpoints.xl}px) {
        font-size: 320px;
    }
`

const StyledLastNameFirstLetter = styled.div``

const StyledLastNameSecondLetter = styled.div`
    position: relative;
    top: 18px;

    @media (min-width: ${Breakpoints.sm}px) {
        top: 21px;
    }
    @media (min-width: ${Breakpoints.md2}px) {
        top: 31px;
    }
    @media (min-width: ${Breakpoints.xl}px) {
        top: 42px;
    }
`

const StyledLastNameEndingLetters = styled.div``

const StyledJobTitle = styled.div`
    position: relative;
    font-size: 15px;
    left: 121px;

    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 15px;
        left: 161px;
    }
    @media (min-width: ${Breakpoints.md2}px) {
        font-size: 21px;
        left: 241px;
    }
    @media (min-width: ${Breakpoints.xl}px) {
        font-size: 30px;
        left: 323px;
    }
`

const Name: React.FC = () => {
    return (
        <StyledName>
            <StyledFirstName>Muhammad</StyledFirstName>
            <StyledLastNameContainer>
                <StyledLastNameFirstLetter>A</StyledLastNameFirstLetter>
                <StyledLastNameSecondLetter>T</StyledLastNameSecondLetter>
                <StyledLastNameEndingLetters>HAR</StyledLastNameEndingLetters>
            </StyledLastNameContainer>
            <StyledJobTitle>Designer at 🧡 Dev by 💼</StyledJobTitle>
        </StyledName>
    )
}

export default Name
