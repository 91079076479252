import React from 'react'
import styled from 'styled-components'

import { Moon, Sun } from '@styled-icons/fa-solid'

import { useDispatch, useSelector } from 'react-redux'
import { makeItBright, makeItDark, selectTheme } from 'redux/themeSlice'
import { addTransition, Breakpoints } from 'theme'

const StyledToggleBase = styled.div`
    width: 70px;
    height: 40px;
    background-color: ${({ theme }) => `${theme.foreground}`};
    border-radius: 20px;
    position: absolute;
    left: calc(100% - 20px);
    transform: translateX(-100%);
    cursor: pointer;
    ${addTransition('background-color')}

    @media (max-width: ${Breakpoints.md2 - 1}px) {
        bottom: 20px;
    }
    @media (min-width: ${Breakpoints.md2}px) {
        top: 20px;
    }
`

type KnobProps = {
    isDark: boolean
}

const StyledToggleKnob = styled.div<KnobProps>`
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: ${({ theme }) => `${theme.background}`};
    position: absolute;
    top: 3px;
    transform: translateX(8%);
    ${({ isDark }) => isDark && `transform: translateX(98%)`};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    ${addTransition('background-color transform')}
`

const StyledMoon = styled(Moon)`
    color: ${({ theme }) => `${theme.foreground}`};
    margin-right: 2px;
    ${addTransition('color')}
`

const StyledSun = styled(Sun)`
    color: ${({ theme }) => `${theme.foreground}`};
    ${addTransition('color')}
`

const Knob: React.FC<KnobProps> = (props) => {
    const { isDark } = props

    return (
        <StyledToggleKnob isDark={isDark}>
            {!isDark ? <StyledMoon size={24} /> : <StyledSun size={24} />}
        </StyledToggleKnob>
    )
}

const Toggle: React.FC = () => {
    const theme = useSelector(selectTheme)
    const dispatch = useDispatch()

    const handleClick = () => {
        switch (theme) {
            case 'light':
                dispatch(makeItDark())
                break
            case 'dark':
                dispatch(makeItBright())
                break
            default:
                break
        }
    }

    return (
        <StyledToggleBase onClick={handleClick}>
            <Knob isDark={theme === 'dark' ? true : false} />
        </StyledToggleBase>
    )
}

export default Toggle
