import React from 'react'
import Name from 'components/Name'
import StyledPage from './StyledPage'
import { Contact } from 'components'

const Home: React.FC = () => {
    return (
        <StyledPage>
            <Name />
            <Contact />
        </StyledPage>
    )
}

export default Home
