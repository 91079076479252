import React from 'react'
import StyledPage from './StyledPage'
import styled from 'styled-components'
import { Tool } from 'components'
import CraftData, { LNDTech, UnlockedTech } from 'icons'
import { addTransition, Breakpoints } from 'theme'
import { StarFill } from '@styled-icons/bootstrap/StarFill'
import { IconWithStyles } from '../icons/iconWithStyle'

const StyledWrapper = styled.div`
    width: 900px;
    padding-left: 40px;
    padding-right: 40px;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    user-select: none;

    @media (max-width: ${Breakpoints.sm - 1}px) {
        padding-bottom: 150px;
        padding-top: 200px;
    }
    @media (min-width: ${Breakpoints.sm}px) {
        padding-bottom: 100px;
        padding-top: 250px;
    }
`

const StyledCategoryContainer = styled.div`
    margin-top: 50px;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    padding: 0 20px;
    border-left: 3px solid ${({ theme }) => theme.foreground};
    color: ${({ theme }) => theme.foreground};
    ${addTransition('color, border-color')}

    @media (max-width: ${Breakpoints.sm - 1}px) {
        min-width: 325px;
    }
    @media (min-width: ${Breakpoints.sm}px) {
        min-width: 400px;
    }
`

const StyledInnerWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const StyledHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (max-width: ${Breakpoints.sm - 1}px) {
        font-size: 20px;
    }
    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 28px;
    }
`

const StyledHeaderDescription = styled.div`
    margin-left: 20px;
`

const StyledDisclaimer = styled.div`
    @media (max-width: ${Breakpoints.sm - 1}px) {
        font-size: 20px;
    }
    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 16px;
    }
`

const StyledCategory = styled.div`
    text-align: left;

    @media (max-width: ${Breakpoints.sm - 1}px) {
        font-size: 24px;
    }
    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 32px;
    }
`

const StyledName = styled.span`
    margin-top: 10px;
`

const Toolbelt: React.FC = () => {
    return (
        <StyledPage>
            <StyledWrapper>
                <StyledCategoryContainer>
                    <StyledHeader>
                        {IconWithStyles(StarFill, 32, 100)}{' '}
                        <StyledHeaderDescription>Unlocked</StyledHeaderDescription>
                    </StyledHeader>
                    <StyledHeader>
                        {IconWithStyles(StarFill, 32, 60)}{' '}
                        <StyledHeaderDescription>In Progress</StyledHeaderDescription>
                    </StyledHeader>
                    <StyledHeader>
                        {IconWithStyles(StarFill, 32, 20)}{' '}
                        <StyledHeaderDescription>Locked</StyledHeaderDescription>
                    </StyledHeader>
                </StyledCategoryContainer>
                {Object.keys(CraftData).map((category: any) => (
                    <StyledCategoryContainer key={category}>
                        <StyledCategory>{`${category}:`}</StyledCategory>
                        <StyledInnerWrapper>
                            {CraftData[category].map((Icon: any) => (
                                <Tool
                                    key={Icon.displayName}
                                    unlocked={UnlockedTech[Icon.displayName]}
                                    lnd={LNDTech[Icon.displayName]}
                                >
                                    <Icon />
                                    <StyledName>{Icon.displayName}</StyledName>
                                </Tool>
                            ))}
                        </StyledInnerWrapper>
                    </StyledCategoryContainer>
                ))}
                <StyledCategoryContainer key="disclaimer">
                    <StyledDisclaimer>
                        Disclaimer: The above technologies and icons associated with them belong to their respective
                        owners and organizations.
                    </StyledDisclaimer>
                </StyledCategoryContainer>
            </StyledWrapper>
        </StyledPage>
    )
}

export default Toolbelt
