import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectTheme } from 'redux/themeSlice'
import { addTransition, Breakpoints } from 'theme'
import { motion } from 'framer-motion'

const StyledLogo = styled.svg`
    width: 44px;
    height: 42px;
    margin-bottom: 5px;

    @media (min-width: ${Breakpoints.sm}px) {
        width: 54px;
        height: 52px;
        margin-bottom: 6px;
    }
`

const StyledLogoContainer = styled.div`
    position: absolute;
    left: 20px;
    border-radius: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => `${theme.foreground}`};
    ${addTransition('background-color')}

    & path {
        ${addTransition('stroke')}
    }

    @media (max-width: ${Breakpoints.md2 - 1}px) {
        bottom: 20px;
        width: 75px;
        height: 75px;
    }
    @media (min-width: ${Breakpoints.md2}px) {
        top: 20px;
        width: 100px;
        height: 100px;
    }
`

const logo = {
    hidden: {
        pathLength: 0,
    },
    visible: {
        pathLength: 1,
        transition: { duration: 4, delay: 0.7 },
    },
}

const Logo: React.FC = () => {
    const currentTheme = useSelector(selectTheme)

    return (
        <StyledLogoContainer>
            <StyledLogo viewBox="0 0 54 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5 1C14.5 13.3932 10.2353 40.7436 2 51H14.5C16.2647 34.5897 23.5687 19.376 27 13.8205C30.4313 19.376 37.7353 34.5897 39.5 51H52C43.7647 40.7436 38.7647 13.3932 38.7647 1C39.9901 9.97436 18.6785 37.5385 27 37.5385C35.3215 37.5385 14.2549 12.9658 14.5 1Z"
                    stroke={currentTheme === 'light' ? '#fff' : '#000'}
                    strokeWidth="2"
                    variants={logo}
                    initial="hidden"
                    animate="visible"
                />
            </StyledLogo>
        </StyledLogoContainer>
    )
}

export default Logo
