import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { AnimationControls } from 'framer-motion/types/animation/types'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { addTransition, Breakpoints } from 'theme'

const navTabs = ['Home', 'Craft', 'Projects']

const StyledNavWrapper = styled.div`
    padding: 20px;
    position: absolute;
    top: 0;
    left: calc(50vw - 7px);
    transform: translateX(-50%);
    cursor: default;
`

const NavStyled = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 70px;
    width: 350px;
    font-weight: 400;

    @media (min-width: ${Breakpoints.sm}px) {
        height: 100px;
        width: 500px;
    }
`

const TabStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

// ---------------------------------- //

interface TabCircleStyledTypes {
    name?: string
    active?: string
}

const TabCircleStyled = styled.div<TabCircleStyledTypes>`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
    background-color: transparent;
    z-index: 2;
    border: 5px solid ${({ theme }) => `${theme.foreground}`};
    ${addTransition('border-color')}
    
    @media (min-width: ${Breakpoints.sm}px) {
        width: 50px;
        height: 50px;
    }
`

interface TabCircleProps {
    name: string
    activeTab: string
    setActiveTab: React.Dispatch<React.SetStateAction<string>>
    controls: AnimationControls
    navRef: React.RefObject<HTMLDivElement>
    prevTabRef: React.RefObject<HTMLDivElement>
}

const TabCircle: React.FC<TabCircleProps> = (props) => {
    const { name, activeTab, setActiveTab, controls, navRef, prevTabRef } = props
    const { pathname } = useLocation();

    function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const move = {
            x: e.currentTarget.getBoundingClientRect().left - navRef.current!.getBoundingClientRect().left,
            transition: {
                duration: 0.35,
                ease: 'easeInOut',
            },
        }

        setActiveTab(name)

        if (!(activeTab === name)) {
            controls.start(move)
        }
    }

    return (
        <Link to={{
            pathname: name === 'Home' ? '/' : name.toLowerCase(), state: { from: pathname === 'undefined' ? '/' : pathname }
        }} style={{ zIndex: 3, borderRadius: '100%' }}>
            <TabCircleStyled
                onClick={(e) => handleClick(e)}
                name={name}
                active={activeTab}
                {...(activeTab === name && { ref: prevTabRef })}
            />
        </Link >
    )
}

// ---------------------------------- //

const TabTextStyled = styled.p`
    font-size: 18px;
    color: ${({ theme }) => `${theme.foreground}`};
    user-select: none;
    ${addTransition('color')}

    @media (min-width: ${Breakpoints.sm}px) {
        font-size: 24px;
    }
`

// ---------------------------------- //

const TabSelectCircle = styled.div`
    cursor: pointer;
    position: absolute;
    top: 20px;
    z-index: 1;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 5px solid ${({ theme }) => `${theme.foreground}`};
    background-color: ${({ theme }) => `${theme.foreground}`};
    ${addTransition('background-color, border-color')}

    @media (min-width: ${Breakpoints.sm}px) {
        width: 50px;
        height: 50px;
    }
`

const MotionTabTabSelectCircle = motion(TabSelectCircle)

// ---------------------------------- //

const ComingSoon = styled.p`
    color: ${({ theme }) => `${theme.foreground}`};
    font-family: 'Nanum Pen Script';
    font-size: 20px;
    user-select: none;
    position: absolute;
    ${addTransition('color')}

    @media (max-width: ${Breakpoints.sm - 1}px) {
        left: 95px;
        bottom: -40px;
        transform: rotate(-10deg);
    }
    @media (min-width: ${Breakpoints.sm}px) {
        right: -105px;
        bottom: -40px;
        transform: rotate(10deg);
    }
`

// ---------------------------------- //

const Navigation = () => {
    const [activeTab, setActiveTab] = useState<string>(navTabs[0])
    const navRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const prevTabRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const controls = useAnimation()
    const history = useHistory()

    useEffect(() => {
        history.push(activeTab === 'Home' ? '/' : activeTab.toLocaleLowerCase())
    }, [])

    return (
        <StyledNavWrapper>
            <NavStyled ref={navRef}>
                <MotionTabTabSelectCircle animate={controls} />
                {navTabs.map((tabName) => {
                    return (
                        <TabStyled key={tabName}>
                            <TabCircle
                                name={tabName}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                controls={controls}
                                navRef={navRef}
                                prevTabRef={prevTabRef}
                            />
                            <TabTextStyled>{tabName}</TabTextStyled>
                        </TabStyled>
                    )
                })}
                <TabStyled>
                    <a
                        style={{ display: 'flex', textDecoration: 'none' }}
                        href="https://www.designdev.io"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TabCircleStyled name="blog" />
                    </a>
                    <TabTextStyled>Blog</TabTextStyled>
                </TabStyled>
            </NavStyled>
        </StyledNavWrapper>
    )
}

export default Navigation
