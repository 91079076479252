import { configureStore } from '@reduxjs/toolkit'
import themeReducer from 'redux/themeSlice'

const store = configureStore({
    reducer: {
        theme: themeReducer,
    },
})

export type State = ReturnType<typeof store.getState>
export type ThemeDispatch = typeof store.dispatch
export default store
