import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'

import { Home, Journey, Craft, Projects } from 'pages'
import { Navigation, Cursor, Toggle, Logo } from 'components'

import { LightTheme, DarkTheme, addTransition, Breakpoints } from 'theme'
import { useSelector } from 'react-redux'
import { selectTheme } from 'redux/themeSlice'

import { AnimatePresence } from 'framer-motion'

export const StyledApp = styled.div`
    position: relative;
    background-color: ${({ theme }) => `${theme.background}`};
    overflow: hidden;
    ${addTransition('background-color')}
    cursor: default;

    @media (min-width: ${Breakpoints.sm}px) {
        cursor: none;
    }
`

const App: React.FC = () => {
    const currentTheme = useSelector(selectTheme)
    const location = useLocation()

    const LightOrDark = () => {
        switch (currentTheme) {
            case 'light':
                return LightTheme
            case 'dark':
                return DarkTheme
            default:
                LightTheme
        }
    }

    return (
        <ThemeProvider theme={LightOrDark()}>
            <StyledApp>
                <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.key}>
                        <Route path="/" exact>
                            <Home />
                        </Route>
                        <Route path="/craft" exact>
                            <Craft />
                        </Route>
                        <Route path="/projects" exact>
                            <Projects />
                        </Route>
                    </Switch>
                </AnimatePresence>
                <Cursor />
                <Toggle />
                <Logo />
                <Navigation />
            </StyledApp>
        </ThemeProvider >
    )
}

export default App
